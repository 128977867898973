import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VueResource from 'vue-resource'
import fecha from 'fecha'
import dateDiff from 'date-diff'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions'
import 'firebase/messaging'
import './firebaseInit';
import Toasted from 'vue-toasted';
import VueMq from 'vue-mq'
import imagesLoaded from 'imagesloaded';
import VueScrollTo from 'vue-scrollto'
import VueMeta from 'vue-meta'
var MobileDetect = require('mobile-detect')
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-204336952-1" }
}, router)

// Vue.use(VueGtag, {
//   config: { 
//     id: "G-BVPYKJ8LGF",    
//   },
// }, router);

Vue.config.productionTip = false
Vue.prototype.$deferredPrompt = null

window.firebase = firebase;
//firebase.functions()['region_'] = 'asia-northeast1'
window.imagesLoaded = imagesLoaded
window.fecha = fecha
window.dateDiff = dateDiff

window.addEventListener("beforeinstallprompt", e => {  
  e.preventDefault();
  Vue.prototype.$deferredPrompt = e;
});

navigator.browserVersion = (function(){
  var ua= navigator.userAgent, tem, 
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join(' ');
})();

Vue.use(VueResource);
Vue.use(VueMq, { breakpoints: { sm: 450, md: 1250, lg: Infinity }}) 
Vue.use(Toasted, { position: 'bottom-center', duration: 1400, fullWidth: true, fitToScreen: true})
Vue.use(VueScrollTo)
Vue.use(VueMeta)

// const messaging = firebase.messaging();
// messaging.usePublicVapidKey('BNGeVQLAGh8J3LMkWN3z902QQuMcS0MRkARpyTFOPIMYIjJstsLbhKYyMCjkPl5ezEcHpB9u26r_QmkrStLuaO0')

let messaging = null

if(firebase.messaging.isSupported()) {
  messaging = firebase.messaging();
}

export { messaging }

export const bus = new Vue();

Vue.prototype.$hideLoading = () => {
  setTimeout(() => {
    bus.$emit("show_loading", false)
  }, 100)
}


let app;

// router.afterEach((to, from) => {

// })

firebase.auth().onAuthStateChanged((user)=>{

  router.beforeEach((to,from,next)=>{ 
    var md = new MobileDetect(window.navigator.userAgent);  
    
    if(md.is('bot')) {
      localStorage.setItem("location", "Guwahati")
      localStorage.setItem("city", "Assam")
      next() 
    }

    if(to.matched.some(record=>record.meta.requiresLocation)) {
      if(localStorage.getItem('location') == null || localStorage.getItem('city') == null) {        
        next('/')
      } 
    }

    if(to.matched.some(record=>record.meta.requiresAuth)) {
      if(firebase.auth().currentUser) {
        next()
      } else {      
        next('/sign-in')
      } 
    } else if(to.matched.some(record=>record.meta.requiresGuest)) {
      if(firebase.auth().currentUser) {   
        next('?ref=sign-in')
      } else {
        next()
      }     
    }
    else {
      next()
    } 
  })

  const isInstagramBrowser = navigator.userAgent.includes("Instagram")

  if(!app & !isInstagramBrowser) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app')    
  }

})