import {bus,messaging} from './../main'

export default {
    mounted() {
      // console.log(Notification.permission)
    },
    methods: {
        handleNotificationUserResponse(){
            const thisRef = this            
            this.getMessagingToken()
            .then(function(token){
              thisRef.sendTokenToServer(token)
            })
            .catch(function(err){
              console.log(err)
            })       
          },
          sendTokenToServer(token) {            
            var saveUserToken = firebase.app().functions('asia-east2').httpsCallable('saveUserToken');

            saveUserToken({token: token})
            .then(function(result) {              
              bus.$emit('tokenSentToServer', true)
            }).catch(function(err){
              console.log(err)
              bus.$emit('tokenSentToServer', false)                  
            })  
          },

          getMessagingToken() {            
            return messaging.getToken({vapidKey: "BHkDT0HS9FzfQZjjxFS1mQXvgrJBTCHL5_m7qNks7tX8qnzIaBBQ0dKf1EJENCqj6U33NyNvCIyMXhtclAiMGd0"})
          },

          deleteMessagingToken() {
            return messaging.deleteToken()
          }
    }
}