<template>
	<div>
		<modal :closeButton="false" @close="$emit('close')">
		  <div slot="header" class="center">
		    <h3>Important!</h3>              
		  </div>
		  <div slot="body">
		    <p>
		      We will update you when your order is ready. 
		      <strong>Please allow Notifications when asked to do so.</strong>            
		    </p>
		    <p>Thanks!</p>
		  </div>
		  <div slot="footer">
		    <div class="flex-row align-items-center space-between">
		      <button @click="handleNotificationRequest()" class="btn-wide bg-success text-white">
		        Okay
		      </button>
		    </div>            
		  </div>
		</modal>		
	</div>
</template>

<script>
	// import {bus,messaging} from './../../main';	
	import Modal from './../Modal.vue'
	import NotificationMixin from './../../mixins/NotificationMixin'

	export default {
		methods: {
			handleNotificationRequest(){
			  this.$emit('close')
			  setTimeout(this.handleNotificationUserResponse, 1000)
			}
		},
		components: {
			'modal': Modal
		},
		mixins: [NotificationMixin]
	}	
</script>