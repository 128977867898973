<template>
  <div>
    <div class="fill" v-bind:class="{ hideOverflowY: loading }">
      <!-- Overlay when a product is selected -->
      <div v-bind:class="{ overlay: overlay }"></div>

      <!-- Loading Screen -->
      <div
        v-if="loading"
        class="flex-column dead-center bg-white"
        style="height:100vh;z-index: 100;"
      >
        <template v-if="storeClosed">
          <div>
            <h1>Sorry, we are closed now</h1> 
            <div>Will be back in a few hours! :)</div>
          </div>
        </template>        
        <template v-else-if="pageLoadError">
          Oh oh! We can't seem to connect.
          <p>
            <button @click="retryConnection()" class="btn-primary text-white">
              RETRY
            </button>
          </p>
        </template>
        <template v-else>
          <div
            class="flex-column dead-center bg-white material-card"
            style="width: 250px;height: 100px;border-radius: 8px;"
          >
            <beat-loader :color="color"></beat-loader>
            <em class="text-dark" style="margin-top: 15px;">Loading...</em>
          </div>
        </template>
      </div>

      <!-- Store closed screen -->
      <!-- <div
        v-if="storeClosed"
        class="flex-column dead-center bg-white"
        style="height:100vh;z-index: 100;"
      >
        <div>
          Sorry, we are closed now. Will be back in a few hours!
        </div>
      </div>       -->

      <!-- App Navbar -->
      <!-- <template
        v-if="homeLoaded && $route.path != '/' && $route.name !== 'Product'"
      > -->
      <template
        v-if="$route.path != '/' && $route.name !== 'Product'"
      >
        <app-navbar
          v-bind:cart="cart"
          v-bind:user="currentUser"
          v-if="navbar"
        ></app-navbar>
      </template>

      <div class="full-height">
        <div class=" full-height">
          <div class="bg-default full-height" style="min-height:100vh;">
            <keep-alive v-bind:class="{ hidden: !loading }" include="Home">
              <router-view
                v-bind:loading="loading"
                v-bind:cart="cart"
                v-bind:message="message"
                v-bind:user="currentUser"
                v-bind:data="data"
                v-bind:overlay="overlay"
                v-on:viewCart="getCart($event)"
              ></router-view>
            </keep-alive>
            <template v-if="homeLoaded"> </template>
          </div>
          <app-footer
            v-bind:user="currentUser"              
          ></app-footer>          
        </div>       
      </div>

      <!-- Inform user about notification permission-->
      <notification-modal
        v-if="showNotificationModal"
        @close="showNotificationModal = false"
      ></notification-modal>

      <version-update-modal
        :closeButton="!versionInfo.mandatory"
        v-if="showVersionUpdateModal"
        :currentVersion="versionInfo.current"
        @close="showVersionUpdateModal = false"
      ></version-update-modal>

      <!-- <div v-if="true"
      > -->
      <div v-if="$deferredPrompt && allowPWAInstallPrompt && isMobileDevice">
        <div
          class="animated slideInUp options-bottom faster text-center"
          style="background:linear-gradient(328.98deg, #FCD2D8 0%, #FFA8B2 97.32%);"
        >
          <template v-if="!PWAInstallCompelete && !PWAInstalling">
            <h3 style="margin-bottom:8px;">Experience as an App?</h3>
            <div>
              It is very fast and would install in seconds
            </div>
            <div class="row no-gutters" style="margin-top:15px;">
              <div class="col-6 center" style="padding:7.5px;">
                <button
                  class="btn-wide text-dark card-lite material-shadow-1"
                  @click="install"
                >
                  Sure
                </button>
              </div>
              <div class="col-6 center" style="padding:7.5px;">
                <button
                  class="btn-wide remove-btn-styles text-dark"
                  @click="dismiss"
                >
                  Maybe later
                </button>
              </div>
            </div>
          </template>        
          <div v-if="PWAInstalling">
            <div class="row no-gutters" style="margin-top:15px;margin-bottom:15px;">
              <div class="col-6" style="padding:7.5px;">
                <em>Installing...</em>
              </div>
              <div class="col-6 text-right" style="padding:7.5px;">
                <pulse-loader color="#f15062"></pulse-loader>
              </div>
            </div>            
          </div>
          <div v-if="PWAInstallCompelete">
            <div class="row no-gutters" style="margin-top:15px;margin-bottom:15px;">
              <div class="col-12 center" style="padding:7.5px;">
                Installation Complete!
              </div>
              <div class="col-12 center" style="padding:7.5px;">
                <button
                  class="btn-wide text-dark card-lite bg-white material-shadow-1"
                  @click="openInstalledPWA"
                >
                  OPEN
                </button>                
              </div>
            </div>                           
          </div>        
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// const Navbar = () => import(/* webpackChunkName: "products" */ "./components/Navbar.vue");
import Navbar from './components/Navbar.vue'
import { bus } from "./main";
import BeatLoader from "vue-spinner/src/BeatLoader.vue";
import Home from "./components/Home.vue";
import NotificationModal from "./components/parts/NotificationModal";
import NotificationMixin from "./mixins/NotificationMixin";
import VersionUpdateModal from './components/parts/VersionUpdateModal.vue';
const shortid = require('shortid')
const MobileDetect = require('mobile-detect')
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Footer from './components/Footer.vue'

export default {
   metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: "Buy cakes online from Guwahati's own Home Bakers",
      // all titles will be injected into this template
      titleTemplate: '%s | Cherryfrost Guwahati'
  },
  components: {
    "app-footer":Footer,
    "app-navbar": Navbar,
    "beat-loader": BeatLoader,
    home: Home,
    "notification-modal": NotificationModal,
    VersionUpdateModal,
    'pulse-loader': PulseLoader,
  },
  data() {
    return {
      appKey: 1,
      message: false,
      showNotificationModal: false,
      homeLoaded: false,
      data: "",
      // deferredPrompt: null,
      overlay: false,
      navbar: true,
      color: "#C82643",
      cart: [{ type: "meta" }],
      loading: false,
      loadingError: false,
      pageLoadError: false,
      stillLoading: false,
      showPrompt: true,
      currentLoaderId: null,
      showVersionUpdateModal: false,
      versionInfo: {
        current: "0.0.1",
        mandatory: false
      },
      PWAInstalling: false,
      PWAInstallCompelete: false,
      allowPWAInstallPrompt: false,
      isPWAInstalled: false,
      PWALastPromptDaysDiff: 0,
      storeClosed: false,
      isMobileDevice: false
    };
  },
  computed: {
    currentUser() {
      return !!firebase.auth().currentUser? firebase.auth().currentUser : null
    },
  },
  methods: {
    sendCurrentAccessInfo() {
      var md = new MobileDetect(window.navigator.userAgent);

      let currentTimeAndDate = fecha.format(
        new Date(),
        "ddd, MMM Do 'YY - hh:mm a"
      );
      let deviceDetected = localStorage.getItem("deviceDetected");

      if (deviceDetected) {
        // this.$http
        //   .post("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/oldUserVisit.json", {
        //     id: deviceDetected,
        //     accessedOn: currentTimeAndDate,
        //   })
        //   .then(() => {});
          // .then(() => console.log("old user access"));
      } else {

        let deviceInfo = {
          id: shortid.generate(),
          mobile: md.mobile(),
          tablet: md.tablet(),
          platform: md.os(),
          browser: md.userAgent(),
          memory: window.navigator.deviceMemory,
          accessedOn: currentTimeAndDate,
          browserVersion: navigator.browserVersion,
          pwa: window.matchMedia('(display-mode: standalone)').matches
        };

        this.$http
          .post(
            "https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/newUserVisit.json",
            deviceInfo
          )
          .then(() => {
            localStorage.setItem("deviceDetected", deviceInfo.id);
            // console.log("new user access");
          });
      }
    },
    showNotificationPermission() {
      this.showNotificationModal = true;
    },
    getCart(data) {
      this.cart = data;
    },
    retryConnection() {
      this.loading = true;
      window.location.reload()
    },
    async dismiss() {
      this.$deferredPrompt = null;
      let timestamp = Date.now()  
      localStorage.setItem("deferredPromptTimestamp", timestamp)      
      this.$forceUpdate()      
    },
    async install() {
      let promptRes = await this.$deferredPrompt.prompt();
      
      if(promptRes.outcome == 'dismissed') { 
        this.dismiss()
      }            

      let count = 0
      let relatedApps = []
      this.PWAInstalling = true

      // setTimeout(() => {
      //   relatedApps.push(1)
      // }, 4000)
            

      if ('getInstalledRelatedApps' in window.navigator) {      
        const watchPWAInstallStatus = setInterval(async () => {     

        relatedApps = await navigator.getInstalledRelatedApps();
        this.isPWAInstalled = !!relatedApps.length
        
        // check if already inst

        if(this.isPWAInstalled) {                              
          this.PWAInstallCompelete = true
          this.PWAInstalling = false
          localStorage.removeItem("deferredPrompt")
          clearInterval(watchPWAInstallStatus)
        }

        if(count == 29) {
          console.log("timeout reached")
          this.PWAInstalling = false
          this.$deferredPrompt = null;
          this.$forceUpdate()                                    
          clearInterval(watchPWAInstallStatus)
        }

        count += 1

        }, 1000)        
      } else {
        this.$deferredPrompt = null;
        this.$forceUpdate()                                    
      }
    },
    openInstalledPWA() {
      this.$deferredPrompt = null
      this.$forceUpdate()
      window.open("https://www.cherryfrost.in/")
    },
    getDifferedPrompt() {
      return this.$deferredPrompt
    }
  },
  async created() {

    if(this.$route.path !== '/') {
      this.loading = true                
    }

  var md = new MobileDetect(window.navigator.userAgent);
  this.isMobileDevice = !!md.mobile()

  window.addEventListener("appinstalled", () => {
    console.log("clicked on install")
    // this.$deferredPrompt = null;
  });

    // console.log(`${process.env.BASE_URL}`)

    // let currentDate = Date.now()    
    // localStorage.setItem('savedDate', currentDate)

    setTimeout(() => {          
      if(this.PWALastPromptDaysDiff > 3 || !localStorage.getItem("deferredPromptTimestamp")) {
        this.allowPWAInstallPrompt = true    
      }
    }, 3000)

    // if(localStorage.getItem('savedDate')) {
    //   let savedDate = localStorage.getItem('savedDate')
    //   console.log(new Date(JSON.parse(savedDate)))
    // }

    // INITIALIZATION SCRIPTS
    let res = await this.$http.get("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/versionInfo.json")
    this.versionInfo = res.body


    if(localStorage.getItem('storedVersion') != null) {
        const previousVersion = localStorage.getItem('storedVersion')
        if(previousVersion != this.versionInfo.current) {
            this.showVersionUpdateModal = true
        }
    } else {
      localStorage.setItem("storedVersion", this.versionInfo.current)
    }

    this.$on("close", () => {
      this.showNotificationModal = false;
    });

    bus.$on("newSharedData", (data) => {
      this.data = data;
    });

    bus.$on("goHome", () => {
      // this.message = true;
      localStorage.removeItem("location")
      localStorage.removeItem("city")
      this.$nextTick(() => {
        this.$router.push("/").catch(() => {
          window.location = "/"
        })
      })
    });

    bus.$on("pageLoadError", () => {
      this.pageLoadError = true;
    });

    bus.$on("hide_nav", (event) => {
      this.navbar = event;
    });

    bus.$on("show_overlay", (data) => {
      this.overlay = data;
    });

    bus.$on("loading_error", () => {
      // this.loading = false;
      // this.loadingError = true;
    });

    bus.$on("changeCart", (newCart) => {
      localStorage.setItem("cart", JSON.stringify(newCart));
    });

    bus.$on("forceUpdate", () => {
      this.$forceUpdate();
    });

    bus.$on("checkoutSuccess", async (user) => {
      if (Notification.permission !== "granted") {
        setTimeout(this.showNotificationPermission, 7000)
      } else {
        let token = await this.getMessagingToken()
        if(!user.token.includes(token)) {
          this.handleNotificationUserResponse()
        }
      }
    });

    bus.$on("askNotificationPermisson", () => {
      this.handleNotificationUserResponse();
    });

    bus.$on("triggerPWAInstall", async () => {            
      
      if ('getInstalledRelatedApps' in window.navigator) {              
        let relatedApps = await navigator.getInstalledRelatedApps();
        if(!!relatedApps.length) { return }
      }      
        console.log(this.$deferredPrompt)
        this.allowPWAInstallPrompt = true
        this.$forceUpdate()      
        
    })

    if (localStorage.getItem("cart") != null) {
      this.cart = JSON.parse(localStorage.getItem("cart"));
    }


    let storedDeferredPromptDate = new Date(parseInt(localStorage.getItem("deferredPromptTimestamp")))
    let currentDate = new Date()
    let diff = new dateDiff(currentDate, storedDeferredPromptDate)
    this.PWALastPromptDaysDiff = diff.days()
  },
  async mounted() {
    // messaging.onMessage(function(payload) {
    //   console.log('Message received. ', payload);
    // });
    // console.log(process.env.NODE_ENV == "development")
    // if (!process.env.NODE_ENV == "development") {
      this.sendCurrentAccessInfo();
    // }


    let storeClosedRes = await this.$http.get("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/storeClosed.json")
    this.storeClosed = storeClosedRes.body    

    if(!this.storeClosed) {
      console.log("emitted")
      bus.$on("show_loading", (data) => {
        this.loading = data;
        if (data == false) {
          this.homeLoaded = true;
        } else {
          this.pageLoadError = false;
        }
      });
    }

    // this.$http
    //   .post("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/categories.json", {
    //     id: shortid.generate(),
    //     description: "Collection of Blueberry Cakes",
    //     title: "Blueberry",
    //     position: 6
    //   })
    //   .then(() => {
    //     console.log("done")
    //   })

  },
  watch: {
    cart: function(val, oldVal) {
      localStorage.setItem("cart", JSON.stringify(val));
    },
    loading(newVal) {

      if(newVal) {
        var id = shortid.generate()
        this.currentLoaderId = id
        setTimeout(() => {
          if(id == this.currentLoaderId) {
            this.pageLoadError = true
          }
        }, 15000)
      } else {
        this.currentLoaderId = null
      }
  }
  },
  mixins: [NotificationMixin],
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Julius+Sans+One");

.inline-block {
  display: inline-block;
}

.alert-success {
  background-color: #00a651;
  border-radius: 5px;
  padding: 10px 10px;
  color: white;
  border: 4px solid #1b7943;
}

.alert-warning {
  background-color: #f6e58d;
  border-radius: 5px;
  padding: 10px 10px;
  border: 4px solid #f9ca24;
}

.greyscale {
  /*filter: grayscale(100%);*/
}

.cursor-pointer {
  cursor: pointer;
}
/* 
  .truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

.margin-vertical-10 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-15 {
  margin-top: 15px;
}

.margin-top-5 {
  margin-top: 5px;
}

/* .main-content {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
} */

.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}
.toggle:before {
  content: "";
  position: relative;
  top: 3px;
  left: 3px;
  width: 34px;
  height: 14px;
  display: block;
  background: #9a9999;
  border-radius: 8px;
  transition: background 0.2s ease;
}
.toggle span {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: white;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
  transition: all 0.2s ease;
}
.toggle span:before {
  content: "";
  position: absolute;
  display: block;
  margin: -18px;
  width: 56px;
  height: 56px;
  background: rgba(39, 174, 96, 0.5);
  border-radius: 50%;
  transform: scale(0);
  opacity: 1;
  pointer-events: none;
}

#cbx:checked + .toggle:before {
  /*background: #947ADA;*/
  /*background: #f3a59d;*/
  background: #7dce9f;
}
#cbx:checked + .toggle span {
  /*background: #4F2EDC;*/
  background: #00a651;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2);
}
#cbx:checked + .toggle span:before {
  transform: scale(1);
  opacity: 0;
  transition: all 0.4s ease;
}

.blinking {
  animation: blinkingText 2s infinite;
}
@keyframes blinkingText {
  0% {
    color: #c82643;
  }
  50% {
    color: transparent;
  }
  100% {
    color: #c82643;
  }
}

.logo-text {
  font-family: "Julius Sans One", sans-serif;
  /*font-weight: lighter;*/
}

.header-font {
  font-family: "Julius Sans One", sans-serif;
}

.mb-10 {
  margin-bottom: 10px;
}

.font-size-18 {
  font-size: 18px;
}

/*img[lazy=loading] {
        height: 200px;
        background-color: red;
      }*/

.text-outline {
  text-shadow: -1px -1px 0 #262626, 1px -1px 0 #262626, -1px 1px 0 #262626,
    1px 1px 0 #262626;
}

.material-card {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.strong {
  font-weight: bold;
}

.material-shadow-1 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.mb-15 {
  margin-bottom: 15px;
}

.shadow-text {
  text-shadow: 2px 4px 3px rgba(0, 0, 0, 0.3);
}

select {
  height: 40px;
  width: 100%;
  padding-left: 15px;
  background-color: white;
}

h4,
h5 {
  font-weight: 500;
}

.hide {
  display: none !important;
}

.hideOverflowY {
  max-height: 100vh;
  overflow-y: hidden;
}

.lead-text {
  font-family: "Raleway", "Trebuchet Ms";
}

a {
  text-decoration: none;
  color: black;
}

ol > li {
  margin-bottom: 10px;
}

.container-fluid {
  padding: 0px 7.5px !important;
}

.col-fill {
  margin: 0px 7.5px;
}

.space-between {
  justify-content: space-between;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-end {
  justify-content: flex-end;
}

.space-around {
  justify-content: space-around;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-start {
  justify-content: flex-start;
}

.align-items-center {
  align-items: center;
}

.align-items-start {
  align-items: flex-start;
}

.center-spaced {
  justify-content: space-between;
  align-items: center;
}

.dead-center {
  justify-content: center;
  align-items: center;
}

.flex-row {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.full-height {
  min-height: 100%;
}

.card {
  background-color: #ffffff;
  margin-bottom: 30px;
}

.card-lite {
  background-color: #ffffff;
  margin-bottom: 15px;
}
.card-pad-lite {
  background-color: #ffffff;
  padding-bottom: 15px;
}
.card-10 {
  margin-bottom: 10px;
}

.card-elements {
  min-height: 80px;
}

.remove-btn-styles {
  border-style: none;
  background-color: transparent;
  color: inherit;
}

.text-primary {
  color: #c82643;
}

.text-dark {
  color: #4d4d4d;
}

.textPrimary {
  color: #c82643;
}
.text-white {
  color: #ffffff;
}

.text-default {
  color: #285473;
}

.text-success {
  color: #00a651;
}

.text-danger {
  color: #c82643;
}

.text-right {
  text-align: right;
}

.bg-success {
  background-color: #00a651;
}

.bg-success-light {
  background-color: #d2e7db;
}

.bg-default {
  background-color: #f5f6fa;
}

.bg-primary {
  background-color: #c82643;
}

.bg-dark {
  background-color: #4d4d4d;
}
/*
      .bg-default {
        background-color: #C82643;
      }*/
.bg-white {
  background-color: #ffffff;
}

.bg-transperant {
  background-color: transparent;
}

.material-btn {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 0.45em 0.85em 0.35em 0.85em;
}

.btn-primary-core {
  border: none;
  padding: 0.35em 0.85em;
  border-style: none;
  font-weight: bold;
}

.btn-primary {
  border: none;
  padding: 0.35em 0.85em;
  border-style: none;
  background-color: #c82643;
  color: #ffffff;
  font-weight: bold;
  border-radius: 20px;
  font-size: 16px;
}

.btn-default {
  border: none;
  padding: 0.45em 0.85em;
  border-style: none;
  background-color: #cccccc;
  color: #4d4d4d;
  font-weight: bold;
  border-radius: 8px;
}

.btn-success {
  border: none;
  padding: 0.35em 0.85em;
  border-style: none;
  background-color: #00a651;
  color: #ffffff;
  font-weight: bold;
}

.btn-primary-outline {
  border: none;
  padding: 0.35em 0.85em;
  border-style: none;
  border: 1px solid #c82643;
  color: #c82643;
  background-color: #ffffff;
  font-weight: bold;
}

.btn-wide {
  border-style: none;
  /*background-color: #C82643;*/
  /*color:white;*/
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 10px 0px;
  border-radius: 5px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"] {
  padding: 15px 10px;
  font-size: 14px;
  width: 100%;
  height: 15px;
  border: 1px solid #bdbdbd;
}

input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="tel"]:focus,
select {
  outline: 0;
}

.center {
  text-align: center;
}

.text-center {
  text-align: center;
}

button:focus {
  outline: 0;
}

/* button:focus{
          border: none;
      }       */

.grey {
  color: grey;
}

.sticked-to-top {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.nav-top {
  position: fixed;
  top: 0;
  z-index: 4;
  background-color: white;
  width: 100%;
  box-sizing: border-box;
  /*border-bottom: 1px solid #cccccc;*/
  /*height: 55px;*/
}

.nav-bottom {
  position: fixed;
  bottom: 0;
  padding: 5px 15px;
  /* background-color: #C82643; */
  /* color: white; */
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #e2e1e0;
}

/* .nav-bottom p {
        color:  white;
      } */

.options-center {
  height: 360px;
  border-radius: 15px;
  position: fixed;
  bottom: 35%;
  left: 30%;
  padding: 5px 15px;
  color: black;
  width: 40%;
  box-sizing: border-box;
  z-index: 2;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.options-bottom {
  position: fixed;
  bottom: 0;
  padding: 5px 15px;
  color: black;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  background-color: #f5f6fa;
}

.options-bottom-lite {
  position: fixed;
  bottom: 0;
  padding: 5px 15px;
  color: black;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
  background-color: #ffffff;
}

.options-bottom > p,
.options-center > p {
  display: flex;
  justify-content: space-between;
  padding-right: 7.5px;
  padding-left: 7.5px;
}

.options-bottom nav,
.options-center nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
}

.options-bottom > nav > p,
.options-center > nav > p {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 15px;
  flex-basis: 30%;
  line-height: 1.3em;
  cursor: pointer;
  font-size: 14px;
  border-radius: 8px;
  color: #4d4d4d;
  margin-right: 15px;
  /* border: 3px solid #4d4d4d; */
  /* border-top: 5px solid #4d4d4d; */
}

.options-bottom > p > small:nth-child(2),
.options-center > p > small:nth-child(2) {
  cursor: pointer;
  background-color: white;
  color: #8c8c8c;
  font-size: 1.3em;
}

img[lazy="loading"] {
  min-height: 100px;
  background-color: transparent;
}

.overlay {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: black;
  opacity: 0.6;
}

.overlay-light {
  height: 100vh;
  width: 100%;
  position: fixed;
  z-index: 1;
  background-color: black;
  opacity: 0.3;
}

.slideInUp {
  -webkit-animation: slideInUp 0.5s; /* Safari 4+ */
  -moz-animation: slideInUp 0.5s; /* Fx 5+ */
  -o-animation: slideInUp 0.5s; /* Opera 12+ */
  animation: slideInUp 0.5s; /* IE 10+, Fx 29+ */
}

.slideInDown {
  -webkit-animation: slideInDown 0.5s; /* Safari 4+ */
  -moz-animation: slideInDown 0.5s; /* Fx 5+ */
  -o-animation: slideInDown 0.5s; /* Opera 12+ */
  animation: slideInDown 0.5s; /* IE 10+, Fx 29+ */
}

.fadeInUp {
  -webkit-animation: fadeInUp 0.5s; /* Safari 4+ */
  -moz-animation: fadeInUp 0.5s; /* Fx 5+ */
  -o-animation: fadeInUp 0.5s; /* Opera 12+ */
  animation: fadeInUp 0.5s; /* IE 10+, Fx 29+ */
}

.fadeInDown {
  -webkit-animation: fadeInDown 0.5s; /* Safari 4+ */
  -moz-animation: fadeInDown 0.5s; /* Fx 5+ */
  -o-animation: fadeInDown 0.5s; /* Opera 12+ */
  animation: fadeInDown 0.5s; /* IE 10+, Fx 29+ */
}

.zoomIn {
  -webkit-animation: zoomIn 0.5s; /* Safari 4+ */
  -moz-animation: zoomIn 0.5s; /* Fx 5+ */
  -o-animation: zoomIn 0.5s; /* Opera 12+ */
  animation: zoomIn 0.5s; /* IE 10+, Fx 29+ */
}

.fill {
  height: 100%;
  background-color: #f5f6fa;
  position: relative;
}

.wrapper {
  background-color: #f5f6fa;
  height: 100%;
}

/*modal styles*/
.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* .row{
  margin-left: -7.5px ;
  margin-right: -7.5px ;
} */

.row > .col,
.row > .col-12,
.row > .col-3,
.row > .col-4,
.row > .col-6,
.row > .col-7,
.row > .col-8,
.row > .col-xs-auto {
  padding: 10px 7.5px;
}

/* Ripple magic */
/* button{
  position: relative;
  overflow: hidden;
}

button:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
} */

.hero-card {
  height: 380px;
  /* background: url('https://www.bakefromscratch.com/wp-content/uploads/2020/02/Heilala-Cake-Web-696x557.jpg'); */
  background-image: linear-gradient(
      to bottom,
      rgba(245, 246, 252, 0),
      #cf433636
    ),
    url("https://www.bakefromscratch.com/wp-content/uploads/2020/02/Heilala-Cake-Web-696x557.jpg");

  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.custom-card {
  height: 180px;
  /* background: url('https://i.ndtvimg.com/i/2015-07/625-cakes-customised_626x350_61437647035.jpg'); */

  background-image: linear-gradient(
      to bottom,
      rgba(149, 149, 149, 0.438),
      rgba(149, 149, 149, 0.438)
    ),
    url("https://i.ndtvimg.com/i/2015-07/625-cakes-customised_626x350_61437647035.jpg");
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  color: white;
}

.hero-card-lite {
  background: #1abc9c;
  border-radius: 8px;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  height: 182.5px;
  color: white;
}

.alt-color {
  background: #3498db;
}

.hero-card-lite h2 {
  font-size: 16px;
}

.goto-modal-card {
  padding: 10px;
  /* background: peachpuff; */
  border: 1px solid #ccc;
  border-radius: 8px;
}

#appDeals {
  background-color: white;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-10 {
  padding-top: 10px;
}

@media (min-width: 768px) {
  body {
    background-color: #f5f6fa;
  }

  #appDeals {
    background-color: transparent;
  }

  /* .nav-bottom {
      max-width: 400px !important;      
      bottom: unset;
      top: 65px;
      right: 10px;
    } */

  .center-on-lg {
    margin-top: 20px;
    max-width: 75%;
  }
  .center-on-lg > div:nth-child(2) {
    margin-top: 20px;
  }
}
</style>