<template>
	<modal @close="$emit('close')">
	  <div slot="header">
          <h1 style="color:#1b1b1b;">THANK YOU!</h1>	    
	  </div>
	  <div slot="body">
        <div class="heart-box">
        <div class="heart-logo"></div>
        <div class="heart animate-topright"></div>
        <div class="heart animate-bottomright"></div>
        <div class="heart animate-topleft"></div>
        <div class="heart animate-bottomleft"></div>
        <div class="heart animate-up"></div>
        <div class="heart animate-down"></div>
        <div class="heart animate-left"></div>
        <div class="heart animate-right"></div>
        </div>
	  </div>
	  <div slot="footer">	    
          <p style="color:#4d4d4d;line-height:1.3rem;">Yey! You are one of the early birds to try Cherryfrost. Looking forward to prepare a lovely & delicious cake for you! ❤️ </p>
	    <button v-if="!locationSelected" @click="$emit('close')" class="btn-wide bg-success text-white">
	        Let's Go!
	    </button>
	  </div>
	</modal> 
</template>



<script>
import Modal from './../Modal'

export default {
    data(){
        return {
            locationSelected: false,
            success: 'red',
            locations: {},
            selectedLocation: ''
        }
    },
    created(){        
    },
    methods: {       
    },
    components:{
        'modal': Modal,
    }
}
</script>

<style scoped>

body {
  padding: 0px;
  background-color: ghostwhite;
  box-sizing: border-box;
}

.heart-box {
  position: relative;
  width: 280px;
  height: 180px;
  margin: auto;
}

.heart-logo {
  background: url('./../../assets/cook-hat.png') no-repeat center center;
  background-size: 36%;
  z-index: 100;
  position: absolute;
  width: 100%;
  height: 100%;
}

.heart {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIyMHB4IiBoZWlnaHQ9IjE4cHgiIHZpZXdCb3g9IjAgMCAyMCAxOCIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4gICAgICAgIDx0aXRsZT5oZWFydDwvdGl0bGU+ICAgIDxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPiAgICA8ZGVmcz48L2RlZnM+ICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPiAgICAgICAgPGcgaWQ9ImhlYXJ0IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNi4wMDAwMDAsIC03LjAwMDAwMCkiIGZpbGw9IiNEMDAyMUIiPiAgICAgICAgICAgIDxwYXRoIGQ9Ik0xNS41MjM4MDk1LDI1IEMxNiwyNSAxNiwyNSAxNi40NzYxOTA1LDI1IEwyNC4wOTUyMzgxLDE3IEMyNi41NDk4Njc5LDE0LjUxMzUwNDIgMjYuNTQ5ODY3OSwxMC45MDAxNDM2IDI0LjA5NTIzODEsOSBDMjIuMTUwOTIzNSw2LjQ0MjgzNjc3IDE4LjU4NDg3NSw2LjQ0MjgzNjc3IDE2LjQ3NjE5MDUsOSBDMTYsOSAxNiw5IDE1LjUyMzgwOTUsOSBDMTMuNDE1MTI1LDYuNDQyODM2NzcgOS44NDkwNzY0Niw2LjQ0MjgzNjc3IDcuOTA0NzYxOSw5IEM1LjQ1MDEzMjA3LDEwLjkwMDE0MzYgNS40NTAxMzIwNywxNC41MTM1MDQyIDcuOTA0NzYxOSwxNyBMMTUuNTIzODA5NSwyNSBaIj48L3BhdGg+ICAgICAgICA8L2c+ICAgIDwvZz48L3N2Zz4=");
  background-repeat: no-repeat;
  height: 22px;
  width: 22px;
}

.animate-topright {
  position: absolute;
  -webkit-animation: movetopRight 6.5s 1s infinite;
  -moz-animation: movetopRight 6.5s 1s infinite;
  -o-animation: movetopRight 6.5s 1s infinite;
  -ms-animation: movetopRight 6.5s 1s infinite;
  animation: movetopRight 6.5s 1s infinite;
}

.animate-bottomright {
  position: absolute;
  -webkit-animation: movebottomRight 4.5s 1.7s infinite;
  -moz-animation: movebottomRight 4.5s 1.7s infinite;
  -o-animation: movebottomRight 4.5s 1.7s infinite;
  -ms-animation: movebottomRight 4.5s 1.7s infinite;
  animation: movebottomRight 4.5s 1.7s infinite;
}

.animate-topleft {
  position: absolute;
  -webkit-animation: movetopLeft 8.5s 5.4s infinite;
  -moz-animation: movetopLeft 8.5s 5.4s infinite;
  -o-animation: movetopLeft 8.5s 5.4s infinite;
  -ms-animation: movetopLeft 8.5s 5.4s infinite;
  animation: movetopLeft 8.5s 5.4s infinite;
}

.animate-bottomleft {
  position: absolute;
  -webkit-animation: movebottomLeft 7.5s 4.8s infinite;
  -moz-animation: movebottomLeft 7.5s 4.8s infinite;
  -o-animation: movebottomLeft 7.5s 4.8s infinite;
  -ms-animation: movebottomLeft 7.5s 4.8s infinite;
  animation: movebottomLeft 7.5s 4.8s infinite;
}

.animate-up {
  position: absolute;
  -webkit-animation: moveup 7.5s 2.2s infinite;
  -moz-animation: moveup 7.5s 2.2s infinite;
  -o-animation: moveup 7.5s 2.2s infinite;
  -ms-animation: moveup 7.5s 2.2s infinite;
  animation: moveup 7.5s 2.2s infinite;
}

.animate-down {
  position: absolute;
  -webkit-animation: movedown 5.5s 5.5s infinite;
  -moz-animation: movedown 5.5s 5.5s infinite;
  -o-animation: movedown 5.5s 5.5s infinite;
  -ms-animation: movedown 5.5s 5.5s infinite;
  animation: movedown 5.5s 5.5s infinite;
}

.animate-left {
  position: absolute;
  -webkit-animation: moveleft 5.5s 2.9s infinite;
  -moz-animation: moveleft 5.5s 2.9s infinite;
  -o-animation: moveleft 5.5s 2.9s infinite;
  -ms-animation: moveleft 5.5s 2.9s infinite;
  animation: moveleft 5.5s 2.9s infinite;
}

.animate-right {
  position: absolute;
  -webkit-animation: moveright 5.5s 3.4s infinite;
  -moz-animation: moveright 5.5s 3.4s infinite;
  -o-animation: moveright 5.5s 3.4s infinite;
  -ms-animation: moveright 5.5s 3.4s infinite;
  animation: moveright 5.5s 3.4s infinite;
}

@keyframes movetopRight {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 10%;
    left: 80%;
    opacity: 0;
  }
}
@keyframes movebottomRight {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 90%;
    left: 90%;
    opacity: 0;
  }
}
@keyframes movetopLeft {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 10%;
    left: 20%;
    opacity: 0;
  }
}
@keyframes movebottomLeft {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 90%;
    left: 5%;
    opacity: 0;
  }
}
@keyframes moveup {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 0%;
    left: 45%;
    opacity: 0;
  }
}
@keyframes movedown {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 100%;
    left: 45%;
    opacity: 0;
  }
}
@keyframes moveleft {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 45%;
    left: 10%;
    opacity: 0;
  }
}
@keyframes moveright {
  0% {
    top: 50%;
    left: 50%;
    opacity: 0.9;
  }
  100% {
    top: 45%;
    left: 97%;
    opacity: 0;
  }
}

</style>