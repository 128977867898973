import Vue from "vue";
import VueRouter from "vue-router";
const Home = () => import (/* webpackChunkName: "home" */ '@/components/Home.vue')
const About = () => import (/* webpackChunkName: "static" */ '@/components/About.vue')
const Products = () => import (/* webpackChunkName: "products" */ '@/components/Products.vue')
const RegularCakes = () => import (/* webpackChunkName: "products" */ '@/components/RegularCakes.vue')
const CustomCakes = () => import (/* webpackChunkName: "products" */ '@/components/CustomCakes.vue')
const CustomBentoCake = () => import (/* webpackChunkName: "products" */ '@/components/CustomBentoCake.vue')
const Cart = () => import (/* webpackChunkName: "purchase" */ '@/components/Cart.vue')
const Categories = () => import (/* webpackChunkName: "products" */ '@/components/Categories.vue')
const SignIn = () => import (/* webpackChunkName: "auth" */ '@/components/SignIn.vue')
const Account = () => import (/* webpackChunkName: "auth" */ '@/components/Account.vue')
const Product = () => import (/* webpackChunkName: "products" */ '@/components/Product.vue')
// const Options = () => import (/* webpackChunkName: "purchase" */ '@/components/Options.vue')
const Message = () => import (/* webpackChunkName: "purchase" */ '@/components/Message.vue')
const PrivacyPolicy = () => import (/* webpackChunkName: "static" */ '@/components/PrivacyPolicy.vue')
const Shipping = () => import (/* webpackChunkName: "static" */ '@/components/Shipping.vue')
const Disclaimer = () => import (/* webpackChunkName: "static" */ '@/components/Disclaimer.vue')
const CancellationRefund = () => import (/* webpackChunkName: "static" */ '@/components/CancellationRefund.vue')
const TermsAndConditions = () => import (/* webpackChunkName: "static" */ '@/components/TermsAndConditions.vue')

const Checkout = () => import (/* webpackChunkName: "purchase" */ '@/components/Checkout.vue')
const DeliveryAddress = () => import (/* webpackChunkName: "purchase" */ '@/components/DeliveryAddress.vue')
const Orders = () => import (/* webpackChunkName: "purchase" */ '@/components/Orders.vue')
const NewDeliveryAddress = () => import (/* webpackChunkName: "purchase" */ '@/components/NewDeliveryAddress.vue')
const EditDeliveryAddress = () => import (/* webpackChunkName: "purchase" */ '@/components/EditDeliveryAddress.vue')
const ChangeDeliveryAddress = () => import (/* webpackChunkName: "purchase" */ '@/components/ChangeDeliveryAddress.vue')
const SavedMeals = () => import (/* webpackChunkName: "purchase" */ '@/components/SavedMeals.vue')
const AvailableLocations = () => import (/* webpackChunkName: "purchase" */ '@/components/AvailableLocations.vue')
const Test = () => import (/* webpackChunkName: "dev-test" */ '@/components/Test.vue')
const ContactUs = () => import (/* webpackChunkName: "static" */ '@/components/ContactUs.vue')
const Payment = () => import (/* webpackChunkName: "static" */ '@/components/Payment.vue')

var isLocationSet = () =>  (!!localStorage.getItem("location") && !!localStorage.getItem("city"))

Vue.use(VueRouter);

const routes = [
	// SUPPPORT AND INFO ROUTES
  { path: '/about', component: About, name: 'About' },    
  { path: '/privacy-policy', component: PrivacyPolicy, name:'Privacy Policy'},                  
  { path: '/contact-us', component: ContactUs, name:'Contact Us'},     
  { path: '/shipping', component: Shipping, name:'Shipping'},                  
  { path: '/terms-and-conditions', component: TermsAndConditions, name:'Terms And Conditions'},                  
  { path: '/disclaimer', component: Disclaimer, name:'Disclaimer'},                  
  { path: '/cancellation-and-refund', component: CancellationRefund, name:'Cancellation and Refund'},                  

  // SIGN IN ROUTES
  { path: '/sign-in', name: "SignIn", component: SignIn, meta: { requiresGuest: true }},

  // TRANSACTION ROUTES    
  { path: '/', component: Home, name:'Cherryfrost', redirect: isLocationSet()? '/menu/Bakery' : false },  
  // { path: '/menu', component: Categories, name:'Menu',  meta: {requiresLocation:true} },              
  { path: '/menu', redirect: '/menu/Bakery' },              
  { path: '/menu/:item', component: Products, name: 'Products',  meta: {requiresLocation:true}},        
  { path: '/product/:id', component: Product, name: 'Product',  meta: {requiresLocation:true}},
  // { path: '/options', component: Options,  meta: {requiresLocation:true}},
  { path: '/cart', component: Cart, name: 'Cart',  meta: {requiresLocation:true}},
  { path: '/instant-cakes', component: RegularCakes, name: 'Instant Cakes',  meta: {requiresLocation:true}},
  { path: '/custom-cakes', component: CustomCakes, name: 'Custom Cakes',  meta: {requiresLocation:true}},
  { path: '/custom-bento-cake', component: CustomBentoCake, name: 'Custom Bento',  meta: {requiresLocation:true}},
  { path: '/add-message/:pos', component: Message, name: 'Add Message ',  meta: {requiresLocation:true}},
  { path: '/saved-meals', component: SavedMeals, name: 'Saved Meals',  meta: {requiresLocation:true}},                
  { path: '/delivery-address', component: DeliveryAddress, name:'Deliver To', meta: {requiresAuth:true, requiresLocation:true}},
  { path: '/account', component: Account, name:'Account', meta: {requiresAuth:true, requiresLocation:true}},                                          
  { path: '/new-delivery-address', component: NewDeliveryAddress, name:'New Address', meta: {requiresAuth:true, requiresLocation:true}},                                          
  { path: '/edit-delivery-address', component: EditDeliveryAddress, name:'Edit Address', meta: {requiresAuth:true, requiresLocation:true}},    
  { path: '/change-delivery-address', component: ChangeDeliveryAddress, name:'Addresses', meta: {requiresAuth:true, requiresLocation:true}},            
  { path: '/account/orders', component: Orders, name:'Orders', meta: {requiresAuth:true, requiresLocation:true}},    
  { path: '/checkout', component: Checkout, name:'Checkout', meta: {requiresAuth:true, requiresLocation:true}},                     
  { path: '/available-locations', component: AvailableLocations, name:'Locations'},                     
  { path: '/payment', component: Payment, name:'Payment'},                     

  // TEST ROUTE ----DEVELOPMENT ONLY-----
  { path: '/test', component: Test, name:'Test Component'},        

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;