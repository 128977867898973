<template>
	<div>
		<div class="text-center" style="padding:15px 10px;margin-top:20px;padding-bottom:100px;" >
			<router-link to="/about">About Us</router-link>
			<router-link to="/contact-us">Contact Us</router-link> &nbsp; 
			<router-link v-if="!user" to="/sign-in">Login</router-link>			
			<router-link to="/terms-and-conditions">Terms & Conditions</router-link>
			<router-link to="/privacy-policy">Privacy Policy</router-link> &nbsp; 
			<router-link to="/shipping">Shipping</router-link>
			<router-link to="/cancellation-and-refund">Cancellation & Refund</router-link>
			<router-link to="/disclaimer">Disclaimer</router-link>
		</div>
	</div>
</template>

<script>
export default {
	props: ['user']
}
</script>

<style scoped>
a{
	margin-right: 20px;
	text-decoration: underline;
}

@media(max-width:768px) {
	a {
		margin-right: 10px;
		display: inline-block;
		margin-bottom: 10px;		
	}
}
</style>