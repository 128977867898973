<template>
	<div>
		<modal :closeButton="closeButton" @close="$emit('close')">
		  <div slot="header" class="center">
		    <h3>New Update Available</h3>              
		  </div>
		  <div slot="body">
		    <p>		      
		      <strong>Hit on 'Refresh' to continue</strong>            
		    </p>
		    <p>It will take only a moment</p>
		  </div>
		  <div slot="footer">
		    <div class="flex-row align-items-center space-between">
		      <button @click="handleUpdateRequest()" class="btn-wide bg-success text-white">
		        REFRESH
		      </button>
		    </div>            
		  </div>
		</modal>		
	</div>
</template>

<script>
	
	import Modal from './../Modal.vue'

	export default {
        props: {
            currentVersion: {
                type: String,
                required: true
            },
			closeButton: {
				type: Boolean,
				required: false,
				default: true
			}
        },
		methods: {
			handleUpdateRequest(){
                localStorage.setItem("storedVersion", this.currentVersion)
                location.reload();              			  
			}
		},
		components: {
			'modal': Modal
		},		
	}	

</script>