<!-- No database Call -->

<template>
    <div class="full-height">        
        <div class="flex-column space-between landing-page-bg">
            <!-- FIRST FLEXBOX ITEM -->
            <div class="flex-column dead-center text-center bg-home" style="height:70vh;padding-bottom: 8%;padding-top:10%;background-color:#262626;">
                <div class="center">
                    <img src="./../assets/cherryfrost_logo_192.png" alt="" width="120px;" />
                </div>                
                <div>
                    <h3><span style="display:inline-block;margin-bottom:10px;font-size:36px;"> HELLO! </span><br> WELCOME TO  CHERRYFROST</h3>
                    <!-- <h3 style="letter-spacing:3px;line-height:60px;" :style="{'font-size': $mq!='sm'?'72px':'42px' }" class="text-white shadow-text"> -->
                        <!-- <span class="text-primary">TASTE</span> HEALTHY & DELICIOUS DISHES <span class="text-primary">PREPARED</span> BY EXPERT CHEFS! -->
                        <!-- <span>BECAUSE LIFE’S SWEETER WITH A LITTLE CAKE!</span>                    
                    </h3> -->
                    <!-- <p :style="{'font-size': $mq!='sm'?'40px':'20px' }" class="text-white shadow-text">Beautifully Designed, Exceptional Taste - Delivered To You</p> -->
                </div>
            </div>
                    
            
            <div style="flex-basis: 15%;padding-top:5px;font-size: 24px;background-color: transparent;" class="flex-column dead-center" >
                <div style="flex-basis: 30%;position: relative;bottom: 30px;">
                    <button @click="showModal = true" class="btn-wide bg-primary text-white" style="">
                         SELECT LOCATION
                    </button> 
                </div>
                <p v-if="!user" class="center text-dark" style="font-size: 14px;margin-top: 0px;">
                    Already Have An Account? &nbsp;
                    <strong><router-link to="/sign-in" class="text-success">SIGN IN</router-link></strong>
                </p>
                <p v-else class="center text-dark" style="font-size: 16px;margin-top: 0px;">
                      Hi {{ user.displayName.split(' ')[0] }}!
                     <span v-if="isLocationSet">
                        You can <strong><router-link to="/account" class="text-success">access your account</router-link></strong>
                     </span>
                     <span v-else>
                         Select your location to proceed
                     </span>
                </p>
                <br>
                <br>
            </div>    
        </div>
        <hr style="width:60%; margin:0 auto;">
        <div class="home-info">
            <home-page-info />
        </div>
        <home-welcome-modal v-if="showWelcomeModal" @close="showWelcomeModal = false"/>
        <set-location-modal v-if="showModal" @close="showModal = false"></set-location-modal>     
    </div>
</template>

<script>
import HomeSetLocationModal from './parts/HomeSetLocationModal'
import HomeWelcomeModal from './parts/HomeWelcomeModal.vue'
import {bus} from './../main' 
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import HomePageInfo from './HomePageInfo.vue'

export default {
    props: ['user', 'message'],
    data(){
        return {
            showModal: false,            
            bg: require('@/assets/home-bg.jpg'),
            slideOutNow: false,
            setLocation: '',            
            showWelcomeModal: false
        }
    },
    computed:{
        isLocationSet() {
            return localStorage.getItem('location') != null && localStorage.getItem('city') != null                            
        }
    },
    created(){    
        if(!localStorage.getItem("welcome")) {
            this.showWelcomeModal = true
            localStorage.setItem("welcome", true) 	                    
        }
        bus.$emit('show_loading', false)       
        bus.$emit('slideOutNow')        
    },
    components:{
        'set-location-modal': HomeSetLocationModal,
        'pulse-loader': PulseLoader,
        'home-page-info':HomePageInfo,
        'home-welcome-modal': HomeWelcomeModal
    }
}
</script>

<style,
        HomeWelcomeModal>      
    .landing-page-bg {
        min-height: 100vh;
        /* position:absolute; */
        top:0;
        left:0;
        width:100%;          
    }

    .home-info{
        margin-top: 40px;
    }

    .landing-page-bg>div:nth-child(2) button {
        padding: 15px;
        /*border-radius: 10px;*/
        letter-spacing: 2px;
    }

    .landing-page-bg>div:first-child {
        color: #fdedef;
        padding: 0px 15px;
        /*flex-basis: 50%;*/
        font-size: 16px;        
        font-family:'Lato';
        letter-spacing: 2px;
        background: linear-gradient(to right top, #f15062, #f8707e, #fc8d98, #ffa8b2, #ffc3cb);        
        /* background:url('https://storage.googleapis.com/cherryfrost_master/home-bg-mobile.jpg'); */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;          
    }
    
</style>