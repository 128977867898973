export default {
	data(){
		return {
			egglessPrices: [15,25,30],
			freeDeliveryThreshold: 200,
			// deliveryFee: 25,
			itemsUnavailable: [],			
		}
	},
	computed: {
		currentTimeInHours(){
				let d = new Date()
				return d.getHours()
		},          		
		isCartEmpty(){
			// if(this.cart.length == 1) { return false }							
			return this.cart.filter(item => item.addon == false).length == 0			
		},
		bakeryItemInCart(){
			if(this.cart.filter(item => item.subtype == 'Bakery').length > 0) return true
			else return false
		},
		getCartItems() {
      		return this.cart.filter((item,index) => index > 0)
		},
		hasTestItem() {
			return this.getCartItems.findIndex(item => item.id == 'CFb09QTifwMXzTPg7N8O') !== -1? true : false
		}
	},
	methods: {
		isDeliveryFree(isCustomLocality){
			// CHANGED FREE DELIVERY SYSTEM
			// if(this.getCost() > 300) {
			// 	return true
			// }
			// return isCustomLocality? false : true
			//

			return false
		
		},		
		deliveryCharges(isCustomLocality) {			
			if(this.isDeliveryFree(isCustomLocality)) { return 0 }
			else { return this.deliveryFee(isCustomLocality) }
		},
		deliveryFee(isCustomLocality) {
			// return isCustomLocality? 30 : 0 
			// CHANGED FREE DELIVERY
			return this.hasTestItem? 0 : 60
		},	
		onlyRegularCakeInCart(){
			return this.getCartItems.every(item => item.category == 'Bakedoor Classics')
		},
		forceUpdateCart(){
			this.cart.push({})
			this.cart.splice(this.cart.length-1,1)
		},
		getCartFromLocalStorage(){
			if(localStorage.getItem('cart') != null) {
			  return JSON.parse(localStorage.getItem('cart'))
			}
		},
		getCurrentItemPrice(currentItem,itemVarientTypeIndex, veg) {
			if(currentItem.subtype == 'Bakery' && veg == true) { 
            	currentItem['name'] += ' (Eggless)'				
				return currentItem.variant.price[itemVarientTypeIndex] + this.egglessPrices[itemVarientTypeIndex] 
			}
			return currentItem.variant.price[itemVarientTypeIndex]			
		},
		getItemsBasicInfo(items){
			let itemsBasicInfo = []
			items.forEach(item => {
				let basicItem = {}
				basicItem['id'] = item.id
				basicItem['variant'] = item.weight
				basicItem['qty'] = item.qty
				basicItem['veg'] = item.veg
				basicItem['subtype'] = item.subtype
				itemsBasicInfo.push(basicItem)
			})
			return itemsBasicInfo
		},
		emptyCart(){			
			this.cart.length = []			
			this.cart.push({type: "meta"})			
		},
	    getCost: function() {
		    let sum = 0
		    var cart = this.cart.filter(item => item.type == 'item')
		    cart.forEach(element => { sum += element.price * element.qty });
		    return sum;
		},	
		getTotalCost(isCustomLocality){
			let cost = this.getCost() + this.deliveryCharges(isCustomLocality)
			return Math.round(cost)
		},
	    numItemsInCart() {
	    	let items = this.cart.filter(item => item.type == 'item')
            let total = 0;
            items.forEach(element => {
                total += element.qty;
            });
            return total;
	     },		
	     itemInCart(item){
	     	let itemItemQty = Object.keys(this.cart.filter(item => item.id == item.id)).length
	     	if(itemItemQty > 0) return true
	     	else return false
		 },
		 getTotalNonDiscountedCost(){
		    let sum = 0
		    var cart = this.cart.filter(item => item.type == 'item')
		    cart.forEach(element => { sum += this.getItemNonDiscountedPrice(element.price,element.discount) * element.qty });
		    return sum;			
		 },
		 getItemNonDiscountedPrice(price, discount){
            return Math.round(price/(1 - discount))
		 },
		 checkDeliveryTime(offset, time) {
			let currentTime = new Date();
			let deliveryTime = new Date();
			deliveryTime.setDate(deliveryTime.getDate() + offset);
			deliveryTime.setHours(time - 6, 0, 0, 0);
			var diff = new dateDiff(deliveryTime, currentTime);
			if (diff.minutes() > 0) {
			  return true;
			} else {
			  return false;
			}
		  },		 
	},
	watch: {
		isCartEmpty(newVal, oldVal) {
			if(oldVal == false && newVal == true) {
				this.emptyCart()
			}
		}
	}	
}