<template>
	<transition  name="modal">
		<!-- <div class="modal-mask" v-bind:style="{'height':screenHeight}"> -->
		<div class="modal-mask">
	      <div class="modal-wrapper" @click="closeModal">
	        <div class="modal-container modal-container-width" @click.stop>
						<slot name="modal-close-btn">
							<div v-if="closeButton" style="margin-bottom:-15px;position:relative;bottom:12px;float:right;left:20px;">
								<i @click="$emit('close')" class="icon-cancel-circle font-size-18 text-dark"></i>
							</div>
						</slot>
						<!-- <div style="float:right;position:relative;">
							<i @click="$emit('close')" class="icon-cancel-circle font-size-18 text-dark"></i>
						</div> -->
	          <div class="modal-header">
	            <slot name="header">
	            </slot>
	          </div>

	          <div class="modal-body">
	            <slot name="body">
	            </slot>
	          </div>
				<div class="transaction">
					<slot name="transactions">
					</slot>
				</div>
				<div>
					<slot name="transaction-body">
					</slot>
				</div>
	          <div class="modal-footer">
	            <slot name="footer">
	            </slot>
	          </div>
	        </div>
	      </div>
	    </div>
	  </transition>
</template>

<script>
export default {
	props: {
		closeButton: {
			default: true,
			required: false,
			type: Boolean
		}
	},
	methods: {
		closeModal() {
			if(!this.closeButton) return
			this.$emit('close')
		}
	}
	// computed:{
	// 	screenHeight(){
	// 		if(!window.screen.height) { return '100%' }
	// 		return window.screen.availHeight+'px'
	// 	}
	// }
}
</script>


<style scoped>
	.modal-mask {
	  position: fixed;
	  z-index: 9998;
	  top: 0;
	  left: 0;
	  width: 100%;
	  height: 100%;
	  background-color: rgba(0, 0, 0, .5);
	  display: table;
	  transition: opacity .3s ease;
	}

	.modal-wrapper {
	  display: table-cell;
	  vertical-align: middle;
	}

	.modal-container {
	  width: 320px;
	  margin: 0px auto;
	  padding: 20px 30px;
	  background-color: #fff;
	  border-radius: 5px;
	  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
	  transition: all .3s ease;
	  /*font-family: Helvetica, Arial, sans-serif;*/
		max-height: 80vh;
		overflow-y: auto;
	}

	.modal-header h3 {
	  margin-top: 0;
	  color: #42b983;
	}

	.modal-body {
	  margin: 20px 0;
	}

	.modal-default-button {
	  float: right;
	}

@media(min-width: 640px) {
	.modal-container-width {
		width: 460px !important;
	}
}

</style>