<template>
  <div>
    <div style="height:55px;background-color: #f5f6fa;"></div>
    <nav
      class="nav-top"
      style="box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);"
    >
      <div class="container-fluid">
        <div class="row no-gutters dead-center">
          <div class="col-xs-12 col-lg-9">
            <div class="flex-row space-between align-items-center">
              <div class="flex-row align-items-center">
                <span
                  v-if="$route.path != '/' && $route.path != '/menu/Bakery'"
                >
                  &nbsp;
                  <i class="icon-left" v-on:click="goBack()"></i>
                </span>
                <router-link to="/menu/Bakery" custom>
                  <img
                    src="./../assets/cherryfrost_logo_lite.png"
                    width="25"
                    alt=""
                    style="border-radius:50%;margin-left: 10px;"
                  />
                </router-link>

                <template v-if="$route.name === 'Products'">
                  &nbsp;
                  <span
                    role="button"
                    @click="goHome()"
                    class="flex-row align-items-center justify-content-center heading-text cursor-pointer"
                  >
                    <!-- <h3>&nbsp;{{ $route.params.item }}</h3> &nbsp; -->
                    <h3 style="padding-left:15px;font-size:14px;">
                      <span style="font-size:10px;" class="text-success"
                        >Delivering to</span
                      >
                      <br />
                      {{ getUserLocation }}, {{ getUserCity }} &nbsp;
                      <i class="icon-down-open"></i>
                    </h3>
                    &nbsp;
                  </span>
                </template>
                <template v-else-if="$route.path == '/'">
                  <h3 class="heading-text text-primary">&nbsp; Cherryfrost</h3>
                  &nbsp;
                </template>
                <template v-else>
                  <h3 class="heading-text">&nbsp; {{ $route.name }}</h3>
                  &nbsp;
                </template>
              </div>
              <div class="flex-row align-items-center">
                <div style="padding-right:10px;font-size:20px;">
                  <transition
                    enter-active-class="animated bounceIn"
                    leave-active-class="animated bounceOut"
                  >
                    <router-link
                      to="/account"
                      v-if="newNotification && $route.name != 'Account'"
                    >
                      <i class="icon-attention text-success"></i> &nbsp;
                    </router-link>
                  </transition>

                  <template v-if="numItemsInCart() > 0">
                    <router-link
                      to="/cart"
                      v-if="$route.name != 'Cart' && $route.name != 'Checkout'"
                    >
                      <small>{{ numItemsInCart() }}</small>
                      <i class="icon-bag"></i>
                    </router-link>
                    <span
                      class="text-right"
                      style="font-size: 12px;display:inline-block;padding: 7px;border-radius:8px;"
                      v-if="$route.name == 'Cart'"
                    >
                      <span class="strong"
                        >Cart Total: &#8377;{{ getCost() }}
                      </span>
                      <!-- <br />
                      <small v-if="isDeliveryFree" class="text-success"
                        >Free Delivery</small
                      >
                      <small v-else class="text-primary"
                        >&#8377;{{ freeDeliveryThreshold - getCost() }} left for
                        free delivery</small
                      > -->
                    </span>
                  </template>
                  <span v-else style="color:white;">.</span>
                  <span @click="goTo('/account')">
                    <template v-if="user">
                      <i
                        class="icon-ok-circle text-success"
                        style="position:relative;left:6px;top:3px;font-size: 12px;"
                      ></i>
                      <i class="icon-user"></i>
                    </template>
                    <span v-else
                      >&nbsp; &nbsp; &nbsp;<span
                        class="cursor-pointer"
                        style="font-size:16px;"
                        >Login</span
                      >
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import cartMixin from "./../cartMixin";
import { bus } from "./../main";
import axios from "axios";

export default {
  props: ["cart", "user"],
  data: () => ({
    newNotification: false,
    component_name: "home",
    current_path: "",
  }),
  created() {
    bus.$on("routeChange", (data) => {
      this.component_name = data;
      this.current_path = data;
    });
    bus.$on("unsetUserNotificationEvent", () => {
      this.unsetUserNotification();
    });
    bus.$on("forceUpdate", (func) => {
      func();
      this.$forceUpdate();
      location.reload();
    });
    setInterval(this.checkUserNotifications, 7000);
  },
  methods: {
    checkUserNotifications() {
      if (this.user) {
        axios
          .get("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/notifications/wwbgiPv1YIeWum86uh4Q4jSNg212.json")
          .then((response) => {
              this.newNotification = response.data         
          });
      }
    },
    goHome() {
      bus.$emit("goHome");
    },
    unsetUserNotification() {
      let uid = this.user.uid

      let updateData = { [uid] : false }

      axios
        .put("https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/notifications.json", JSON.stringify(updateData))
        .then(() => { })
        .catch(()=>{ console.log("error in unsetting notification") })
    },
    goTo(location) {
      this.$router.push(location).catch(() => {});
      // if(this.$route.name != 'Account') {
      //   bus.$emit('show_loading', true)
      // }
    },
    goBack() {
      this.$router.go(-1);
      bus.$emit("show_loading", true);
    },
    goHome() {
      bus.$emit("goHome");
    },
  },
  computed: {
    getUserCity() {
      return localStorage.getItem("city");
    },
    getUserLocation() {
      return localStorage.getItem("location");
    },
  },
  watch: {
    newNotification: function(oldVal, newVal) {
      if (this.$route.name == "Account") {
        this.unsetUserNotification();
      }
    },
  },
  mixins: [cartMixin],
};
</script>

<style>
.heading-text {
  font-family: "Raleway", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
}
</style>
