<!-- 1 REST CALL TO FETCH AVAILABLE LOCATIONS -->

<template>
	<modal @close="$emit('close')">
	  <div slot="header">
	    <h3>Select Location</h3>              
	  </div>
	  <div slot="body">
	    <select class="mb-15">
	        <option value="">Assam</option>
	    </select>              
	    <select v-if="Object.keys(locations).length != 0" v-model="selectedLocation">
	        <option :key="location.name" v-for="location in locations">
	        	{{ location.name }}
	        </option>
	    </select>
        <p class="center" v-else> 
            <pulse-loader style="padding: 7.5px 0px;"></pulse-loader>
        </p>
	  </div>
	  <div v-if="Object.keys(locations).length != 0" slot="footer">	    
	    <button v-if="!locationSelected" @click="setUserLocation()" class="btn-wide bg-success text-white">
	        VIEW MENU
	    </button>              
	    <div v-else class="flex-row align-items-center space-between">
	        <div>Fetching Menu</div>
	        <pulse-loader :color="success" style="padding: 7.5px 0px;"></pulse-loader>
	    </div>
	  </div>
	</modal> 
</template>



<script>
import Modal from './../Modal'
import PulseLoader from 'vue-spinner/src/BeatLoader.vue'

export default {
    data(){
        return {
            locationSelected: false,
            success: 'red',
            locations: {},
            selectedLocation: ''
        }
    },
    created(){    	
    	this.$http.get('https://cherryfroststore-default-rtdb.asia-southeast1.firebasedatabase.app/locations.json')
    	.then(response => {
    		return response.json()
    	})
    	.then(data => {
    		this.locations = data            
            this.selectedLocation = Object.values(data)[0].name
    	})
    },
    methods: {
        setUserLocation(){
            this.locationSelected = true
            localStorage.setItem('city', 'Assam');            
            localStorage.setItem('location', this.selectedLocation)
            this.$router.replace('/menu/Bakery')
        },        
    },

    components:{
        'modal': Modal,
        'pulse-loader': PulseLoader
    }
}
</script>